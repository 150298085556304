.mandaatti-container {
  max-width: 50vw;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
  color: #333;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
  min-height: 60px;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #45a049;
}

.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  appearance: none; /* Poista oletusnuoli */
  background-color: #fff; /* Taustaväri */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23444" d="M7 10l5 5 5-5H7z"/></svg>'); /* Lisätään nuoli */
  background-repeat: no-repeat;
  background-position: right 10px center; /* Nuolen sijainti */
  background-size: 12px; /* Nuolen koko */
  transition: border-color 0.3s ease; /* Siirtymän lisääminen */
}

.form-group select:focus {
  border-color: #4CAF50; /* Korosta reunus fokusoitaessa */
  outline: none; /* Poista oletusfokus */
}
