.App {
  text-align: center;
  display: grid;
  grid-template-columns:  0% 30% 23.33% 23.33% 23.33%;
  background-color: #fff;
  overflow: hidden;
  height: 100vh; /* Muuta korkeus 95vh, jotta navigointipalkille jää tilaa */
  width: 100vw;
  font-size: 0.9em;
  margin-top: 5vh; /* Lisää ylämarginaali, jotta sisältö ei mene navigointipalkin päälle */
  margin: 0;
}
.LomakeLogo {
  display: none;
}
.LomakeTitle {
  display: none;
}

.FormNav{
  height: 5vh;
  width: 100vw;
  background-color: rgb(1, 4, 28);
  display: flex;
  justify-content: flex-end; 

}
.flagForm{
  height:2.5vh;
  margin-top:0vh; 
  margin-right:1vw;
  cursor:pointer;
}
.support{
  position: absolute;
  bottom: 0;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  color: black;
}

.Feedback {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 70vh;
  transform: translate(-50%, -50%);
  width: 50vw;
  text-align: center;
  background-color: white;
  opacity: 1;
  overflow: hidden;
  border-radius: 5px;
}
.FeedbackButton{

    margin-top: 2vh;
    align-self: flex-end;
    border-radius: 2px;
    padding: 5px;
    width: 8vw;
    
}
.SimulatorModal{
  position: fixed;
  top: 50%;
  left: 50%;
  height: 70vh;
  transform: translate(-50%, -50%);
  width: 50vw;
  text-align: center;
  background-color: white;
  opacity: 1;
  overflow-y: auto;
  padding-bottom: 2vh;
  border-radius: 5px;
}
.CloseFeed {
  font-size: 1.5em;
  margin-top: 0.8em;
  margin-right: 0.8em;
  cursor: pointer;
}
.MainTitle{
  font-size: 4em;
  color: white;
  margin-top: 2vh;
  line-height:0.9em;
}
.AppModalOverlay {
  background-color: rgba(255, 0, 0, 0.5) ;
}
.FormLogo{
  display: block;
}

.AppModal {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 70vh;
  transform: translate(-50%, -50%);
  width: 33vw;
  background-color: white;
  opacity: 1;
  overflow: hidden;
  border-radius: 5px;
}
.ChoiceModal{
  position: fixed;
  top: 50%;
  left: 50%;
  height: 55vh;
  transform: translate(-50%, -50%);
  width: 40vw;
  background-color: white;
  opacity: 1;
  overflow: hidden;
  border-radius: 5px;

}
.Enter {
  color: red;
  font-size: 4em;
  z-index: 10;
}
.SignIn{
  margin-top: 2vh;
  margin-left: 40%;
  width: 20%;
  
}
.Footer{
  display: block;
  color: white;
  position: fixed;
  bottom: 1vh;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center; 
  font-size: 1em;
}


.Front {
  height: 0vh;

}
.AI {
  width: 100vw;
  height: 95vh;
}
.AI2 {
  width: 100vw;
  height: 100vh;
}
.navbar {
  height: 5vh;
  width: 100vw;
  background-color: black;
  top: 0;
  left: 0;
  z-index: 100;
  
 
}
.button1 {
  background-color: #b11a1a; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 2px;
}
.button2 {
  background-color: #2c83c8; /* Green */
  border: none;
  color: white;
  margin-top: 5vh;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 2px;
}
.button10 {
  background-color: #b11a1a; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding-left: 3vw;
  font-size: 1.2em;
  max-width: 35vw;
  
  
}
.Left {
  background-color: #142e64;
  height: 100vh;
  padding-top: 4vh;
  
}
.ChatTxt{
  font-size: 10em;
}






.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  
  
}

.form-content {
  margin-top: -3vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-between;
  height: 25vh;
 
  text-align: center;
  position: relative;
  
}

.dropdown-toggle,
.dropdown-toggle:hover,
.dropdown-toggle:focus,
.dropdown-menu {
  border-radius: 0px;
}

.dropdown-toggle,
.dropdown-toggle:hover,
.dropdown-toggle:focus {
  background-color: #fafafa !important;
  color: black !important;
  border: 1px solid #ccc !important;
  width: 100%;
}

.dropdown {
  border-radius: 0px;
  width: 100% !important;
}
.dropdown-menu{ 
  width: 100%;
}

.search-input {
  margin-top: 1vh;
}

.search-formcontrol {
  width: 17vw;
  border-radius: 1px;
  text-align: center;
}

.listwords {
  cursor: pointer;
  
}

.list-container { 
  width: 100%;
  overflow-y: scroll;
  max-height: 38vh;
}

.position-input {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.form-control-textarea {
  width: 15vw;
  border-radius: 1px;
}

.toggle-button-group {
  display: flex;
  justify-content: center;
}

.button-container {
  margin-top: 3vh;
  display: flex;
  justify-content: center;
}

.next-button,
.submit-button {
  border-radius: 1px;
}








.myButton {
  margin-left: 3vw;
  margin-right: 1vw;
  width: 10vw;
  top: 0;
  margin-top: -15vh;
  background-color: #55a1e0;
}

.MainPics {
  height: 22vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5vh; 
}
.Logo {
  height: 5vh;
  margin-top: -1vh;
  display: block;
  padding-left: 3vw;
  
}


.Centre{
  background-color: #ff914d;
  height: 100vh;
  overflow-y: scroll;
  padding-top: 7vh;
}
.Right {
  background-color: #2c83c8;
  height: 100vh;
  overflow-y: scroll;
  padding-top: 7vh;
}
.Ind {
  background-color: #86c0da;
  height: 100vh;
  overflow-y: scroll;
  padding-top: 7vh;
}
.Question {
  display: inline;
  cursor: pointer;
  font-size: 0.9em;
  
}
.QuestionTitle{
  color:whitesmoke;
  font-size: 1.6vw;
}
.Question:hover {
  font-weight: 600;
  cursor: pointer;
  
}

.Questions {
  text-align: left;
  width: 90%;
  margin-left: 5%;
  font-size: 1.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-shadow: 0px 0px 1px #2c83c8;
}

.Copy {
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 500;
 

  /* uusi rivi: asettaa ikonin oikealle */
  margin-left: auto;
  
}
.Copy2 {
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 500;


  /* uusi rivi: asettaa ikonin oikealle */
  margin-left: auto;

  animation: copied 1s ease ;
  opacity: 0;
}
.Close {
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 500;
  margin-right: 0.7vw;
}

.closebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0.7vw;
  text-align: right;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  
}

.closebar2 {
  position: -webkit-sticky;
  position: sticky;
  top: 2.5em;
  right: 0;
  text-align: right;
  z-index: 1000;
 
  width: 95%;
  /* lisää seuraava rivi */
  left: auto;
  /* uusi rivi: keskittää sisällön pystysuunnassa */
  display: flex;
  align-items: center;
  flex-direction: column;

}

.Canvas {
  width:20vw;
  height:2.2vh; 
  background-color:green; 
  height:2.2vh;
  width: 20vw;
  background-color: #ecf0f1;
  border: 2px solid black;
}
.Canvas2 {
  

  width:20vw;
  height:2.2vh; 
  background-color:green; 
  height:2.2vh;
  width: 20vw;
  background-color: #ecf0f1;
  border: 2px solid black;

}
.Volume2 {
  padding-bottom: 0vh;
  margin-bottom: 0vh;
  
}
.Flags {
 
  text-align: center;
  width: 100%;
  margin-top: 8vh;
 
}
.Flag {
  margin: 1.5vh; 
  cursor: pointer;
 
}
.FlagId {
  height:9vh;
  width:8vw;
  object-fit: cover;
  border:1px solid black;
}

.Count {
  font-size: 12em;
  font-weight: bolder;
  text-shadow: 2px 2px 8px black;
  animation: Counter 1s ease infinite;
}
.CountBlock {
  margin-top: 1em;
  
}
.Talking {
  font-size: 5em;
  animation: Talk 1s ease;
  opacity: 0;
}
.TalkingBlock {
  margin-top: 5em;

}
.Count2 {
  font-size: 8em;
  font-weight: bolder;
  text-shadow: 2px 2px 8px black;
  animation: Counter 1s ease infinite;
}
.CountBlock2 {
  margin-top: 0em;
  text-align:center ;
  
}
.Talking2 {
  font-size: 3em;
  animation: Talk 1s ease;
  opacity: 0;
}
.TalkingBlock2 {
  text-align:center ;
  margin-top: 5em;

}


.logopikku {
  margin-top: 10vh;
  margin-bottom: 3vh;
  height: 10vh;
  animation: rotate 1.5s linear infinite;
}
.logopikku2 {
  margin-top: 10vh;
  margin-bottom: 3vh;
  height: 5vh;
  animation: rotate 1.5s linear infinite;
}
.Listwords:hover {
  cursor: pointer;
  font-weight: 600;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lds-ring {
  margin-top: 2em;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #2c83c8;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2c83c8 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}


/* width */
::-webkit-scrollbar {
  width: 1vw;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fafafa; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #808285; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #808285; 
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes Counter {
  0% { opacity: 1}
  50% { opacity: 0.8;}
  100% { opacity: 0;}
  
}
@keyframes Talk {
  0% { opacity: 1}
  50% { opacity: 0.8;}
  80% { opacity: 0;}
  
}
@keyframes copied {
  0% { opacity: 1}
  50% { opacity: 0.5;}
  80% { opacity: 0;}
  
}
/*
@keyframes CounterMargin {
  0% {
    margin-top: 1em;
    
  }
  80% {
    margin-top: -5em;
  }
  
}*/

@media only screen and (max-width: 1200px) {

  .AppModal {
    position: fixed;
    top: 50%;
    left: 50%;
    height: 70vh;
    transform: translate(-50%, -50%);
    width: 50vw;
    background-color: white;
    opacity: 1;
    overflow: hidden;
  }

.Count {
  font-size: 6em;
  font-weight: bolder;
  text-shadow: 2px 2px 8px black;
  animation: Counter 1s ease infinite;
}
.Talking {
  font-size: 2em;
  animation: Talk 1s ease;
  opacity: 0;
}
.MainPics{
  height: 20%;
}
.MainTitle{
  font-size: 3em;
}
.ChoiceModal{
  position: fixed;
  top: 42%;
  left: 50%;
  height: 60vh;
  transform: translate(-50%, -50%);
  width: 60vw;
  background-color: white;
  opacity: 1;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 6vh;

}

}

@media only screen and (max-width: 1000px) {

  .AppModal {
    position: fixed;
    top: 50%;
    left: 50%;
    height: 70vh;
    transform: translate(-50%, -50%);
    width: 60vw;
    background-color: white;
    opacity: 1;
    overflow: hidden;
  }
  .Footer{
    display: block;
    color: white;
    position: fixed;
    bottom: 1vh;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center; 
    font-size: 1em;
  }
 
  .Close {
    cursor: pointer;
    font-size: 1.5em;
    font-weight: 500;
    margin-right: 1vw;
  }
  .dropdown-toggle,
  .dropdown-toggle:hover,
  .dropdown-toggle:focus,
  .dropdown-menu {
    border-radius: 0px;
    width: 50vw !important;
  }
  
  .dropdown-toggle,
  .dropdown-toggle:hover,
  .dropdown-toggle:focus {
    background-color: #f8f9fa !important;
    color: black !important;
    border: 1px solid #ccc !important;
    width: 50vw !important;
  }
  
  .closebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    right: 1vw;
    text-align: right;
    z-index: 1000;
    
  }
  .Count {
    font-size: 6em;
    font-weight: bolder;
    text-shadow: 2px 2px 8px black;
    animation: Counter 1s ease infinite;
  }
  .Talking {
    font-size: 2em;
    animation: Talk 1s ease;
    opacity: 0;
  }
  .SignIn{
    margin-top: 2vh;
    margin-left: 10%;
    width: 80%;
    
  }
  .FeedbackButton{

    margin-top: 2vh;
    align-self: flex-end;
    border-radius: 2px;
    padding: 5px;
    width: 15vw;
}
  .MainTitle{
    font-size: 4em;
    color: white;
    margin-top: -0vh;
    line-height:0.9em;
  }
  .MainPics {
    width: 80%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5vh; 
    margin-bottom: 2vh;
  }
  .Left {
    background-color: #142e64;
    height: auto;
    padding-top: 1vh;
    
  }
  .Question {
    display: inline;
    cursor: pointer;
    font-size: 4vw;
    
  }
  .QuestionTitle{
    color:whitesmoke;
    font-size: 5.5vw;
    margin-top: -2vh;
  }
  .Question:hover {
    font-weight: 600;
    cursor: pointer;
    
  }
  
  .Questions {
    text-align: left;
    width: 90%;
    margin-left: 5%;
    font-size: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-shadow: 0px 0px 1px #2c83c8;
  }
  .AI {
    height: 50vh;
    margin-top: auto;
    margin-bottom: auto;
  }

  .Front {
    height: 100vh;
    background-color: black;

  }
  .Flags {
 
    text-align: center;
    width: 100%;
    margin-top: 8vh;
   
  }
  .Flag {
    margin: 2vw; 
    cursor: pointer;
    background-color: red;
   
  }
  .FlagId {
    height:8vh;
    width:15vw;
    object-fit: cover;
    border:1px solid black;

  }
  .Feedback {
    position: fixed;
    top: 50%;
    left: 50%;
    height: 70vh;
    transform: translate(-50%, -50%);
    width: 80vw;
    text-align: center;
    background-color: white;
    opacity: 1;
    overflow: hidden;
    border-radius: 5px;
  }
  @media only screen and (max-width: 769px) {
    .NavBar{
      height: 10vh;
    }
    .AppModal {
      position: fixed;
      top: 50%;
      left: 50%;
      height: 70vh;
      transform: translate(-50%, -50%);
      width: 60vw;
      background-color: white;
      opacity: 1;
      overflow: hidden;
    }
    .AI {
      height: 50vh;
      margin-top: auto;
      margin-bottom: auto;
    }
    .Canvas {
      width:30vw;
      height:2.2vh; 
      background-color:green; 
      height:2.2vh;
      background-color: #ecf0f1;
      border: 2px solid black;
    }
 
  .SimulatorModal{
    position: fixed;
    top: 50%;
    left: 50%;
    height: 70vh;
    transform: translate(-50%, -50%);
    width: 80vw;
    text-align: center;
    background-color: white;
    opacity: 1;
    overflow-y: auto;
    border-radius: 5px;
  }
  }
  @media only screen and (max-width: 600px) {

    .FlagId {
      height:9vh;
      width:24vw;
      object-fit: cover;
      border:1px solid black;
      
    }
    .FormLogo{
      display: none;
    }
    .FormTitle{
      display: none;
    }
    .Card {
      height: 37vh !important;
    }
    .Footer{
      display: block;
      color: white;
      position: fixed;
      bottom: 1vh;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center; 
      font-size: 0.7em;
    }
    .support{
      position: absolute;
      bottom: 0;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      color: black;
    }
    .MainTitle{
      font-size: 2.5em;
      color: white;
      margin-top: -0vh;
      line-height:0.9em;
    }
    .formInfo{
      font-size: 0.9em;
    }
    .flagForm{
      height:2.5vh;
      margin-top:0vh; 
      padding-right:73vw;
      cursor:pointer;
    }
    .FormHome2{
      display: none;
    }
    .FormNav{
      height: 0vh !important;
      width: 100vw!important;
      background-color: rgb(11, 1, 31)!important;
      display: flex!important;
      justify-content: flex-end!important; 
      
    }
    .FormHome{
     margin-top: 2vw; 
     font-size: 1.7em !important;
    }
    .SignIn{
      width: 50vw !important;
      
    }
    .forgot {
      font-size: 0.9em !important;
    }
    .change {
      font-size: 0.9em !important;
      margin-top: -0.1em !important;
    }
    .Dropdown-menu {
      font-size: 0.75em !important;
    }
    .dropdown-toggle,
    .dropdown-toggle:hover,
    .dropdown-toggle:focus,
    .dropdown-menu {
      border-radius: 0px;
      width: 70vw !important;
    }
    
    .dropdown-toggle,
    .dropdown-toggle:hover,
    .dropdown-toggle:focus {
      background-color: #f8f9fa !important;
      color: black !important;
      border: 1px solid #ccc !important;
      width: 70vw !important;
    }
    .droppis {
      font-size: 1.1em !important;
    }
    .submit-button {
      font-size: 0.9em !important;

    }
    
  
    .QuestinCurrent{
      font-size: 1em;
      padding: 2vw;
    }
    
    .AI {
      display: none;
    }
    .LomakeLogo {
      height: 10vh;
      display: block;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      padding-top: 3vh;
    }
    .LomakeTitle {
      color: #dddddd;
      display: block;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      
    }
    .AppModal {
      position: fixed;
      top: 50%;
      left: 50%;
      height: 70vh;
      transform: translate(-50%, -50%);
      width: 90vw;
      background-color: white;
      opacity: 1;
      overflow: hidden;
      margin-top: 3vh;
    }
    
    .SimulatorModal{
      position: fixed;
      top: 50%;
      left: 50%;
      height: 70vh;
      transform: translate(-50%, -50%);
      width: 90vw;
      text-align: center;
      background-color: white;
      opacity: 1;
      overflow-y: auto;
      border-radius: 5px;
    }
    .Close {
      cursor: pointer;
      font-size: 1.5em;
      font-weight: 500;
      margin-right: 3vw;
    }
    
    .closebar {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      right: 3vw;
      text-align: right;
      z-index: 1000;
      
    }
    
    
    .button2 {
      background-color: #2c83c8; /* Green */
      border: none;
      color: white;
      margin-top: 5vh;
      padding: 10px 24px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 12px;
      cursor: pointer;
      border-radius: 2px;
    }
    .MainPics {
      width: 80%;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0.5vh; 
      margin-bottom: 2vh;
    }
    .FeedbackButton{

      margin-top: 2vh;
      align-self: flex-end;
      border-radius: 2px;
      padding: 5px;
      width: 30vw;
  }
    .Feedback {
      position: fixed;
      top: 50%;
      left: 50%;
      height: 70vh;
      transform: translate(-50%, -50%);
      width: 95vw;
      text-align: center;
      background-color: white;
      opacity: 1;
      overflow: hidden;
      border-radius: 5px;
    }
  }
  .ChoiceModal{
    position: fixed;
    top: 42%;
    left: 50%;
    height: 60vh;
    transform: translate(-50%, -50%);
    width: 90vw;
    background-color: white;
    opacity: 1;
    overflow: hidden;
    border-radius: 5px;
    margin-top: 6vh;
  
  }
  .Enter {
    text-align: center;
    margin-top: 5vh;
    animation: pulse 2s infinite;
    color: #6f787a;
    font-weight: 600;
    text-shadow: 0 0 0px white;
  }
  .App{
    text-align: center;
    display: flex;
    background-color: #fff;
    flex-direction: column;
    overflow: hidden;
    height: auto; /* Muuta korkeus 95vh, jotta navigointipalkille jää tilaa */
    width: 100vw;
    font-size: 0.9em;
    margin-top: 5vh; /* Lisää ylämarginaali, jotta sisältö ei mene navigointipalkin päälle */
    margin: 0;
  }
  .Left{
    order: 1;
  }
  .Centre {
    order: 3;
  }
  .Right{
    order: 4;
  }
  .Ind {
    order: 2;
  }

form-control-textarea{
  border-radius: 0px !important;
}
.list-container{
  font-size: 0.7em !important;
}
position-input{
  border-radius: 0px !important;
  
}
form-control-textarea{
  border-radius: 0px !important;
 
}
@media only screen and (max-width: 300px) {
  .ChoiceModal{
    position: fixed;
    top: 42%;
    left: 50%;
    height: 60vh;
    transform: translate(-50%, -50%);
    width: 95vw;
    background-color: white;
    opacity: 1;
    overflow: hidden;
    border-radius: 5px;
    margin-top: 5vh;
  
  }
  .AppModal{
    width: 97vw;
  }
  .LomakeTitle{
    font-size: 1.5em !important;
  }
  .forgot {
    font-size: 0.7em !important;
  }
  .change {
    font-size: 0.7em !important;
    margin-top: 0.3em !important;
  }
  .dropdown-toggle,
  .dropdown-toggle:hover,
  .dropdown-toggle:focus,
  .dropdown-menu {
    border-radius: 0px;
    width: 90vw !important;
  }
  
  .dropdown-toggle,
  .dropdown-toggle:hover,
  .dropdown-toggle:focus {
    background-color: #f8f9fa !important;
    color: black !important;
    border: 1px solid #ccc !important;
    width: 90vw !important;
  }
}

  @keyframes pulse {
    0% {
      opacity: 1;
      text-shadow: 0 0 3px #2c83c8;
    }
    30% {

      text-shadow: 0 0 2px #2c83c8;
    }
    50% {
      opacity: 0.5;
      text-shadow: 0 0 0px #2c83c8;
    }
    70% {
    
      text-shadow: 0 0 2px #2c83c8;
    }
    100% {
      opacity: 1;
      text-shadow: 0 0 3px #2c83c8;
    }
  }
 
  
}